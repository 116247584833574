import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  fetchPrivacyPolicy,
  fetchTermsOfService,
} from '../../common/services/LoginEdekaService';
import parse, {
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import {Body, Heading} from '@edekadigital/b2c-components';
import {Typography} from '@mui/material';
import {styled} from '@mui/material';

type LegalDocumentType = 'terms-of-service' | 'privacy-policy';

interface LegalDocumentProps {
  type: LegalDocumentType;
}

const headlines = {
  'terms-of-service': 'AGB',
  'privacy-policy': 'Datenschutz',
};

const fetchLegalDocument = (type: LegalDocumentType) => {
  switch (type) {
    case 'terms-of-service':
      return fetchTermsOfService();
    case 'privacy-policy':
      return fetchPrivacyPolicy();
  }
};

const options: HTMLReactParserOptions = {
  replace: domNode => {
    if (!(domNode instanceof Element)) {
      return;
    }
    const {name, children} = domNode;
    if (name === 'h1') {
      return (
        <Heading variant={'h3'} component={'h1'} gutterBottom={true}>
          {domToReact(children)}
        </Heading>
      );
    }

    if (name === 'h2') {
      return (
        <Heading variant={'h4'} component={'h2'} gutterBottom={true}>
          {domToReact(children)}
        </Heading>
      );
    }

    if (name === 'p') {
      return <Body gutterBottom={true}>{domToReact(children)}</Body>;
    }

    if (name === 'ul') {
      return (
        <Typography variant="body2" component="ul">
          {domToReact(children)}
        </Typography>
      );
    }
    return;
  },
};
const StyledDiv = styled('div')({
  overflowWrap: 'break-word',
});

const LegalDocument: React.FC<LegalDocumentProps> = ({type}) => {
  const headline = (
    <Heading variant={'h3'} component={'h1'} gutterBottom={true}>
      {headlines[type]}
    </Heading>
  );
  const [legalDocumentText, setLegalDocumentText] = useState<
    string | JSX.Element | JSX.Element[]
  >(
    <>
      {headline}
      <Body>Lade…</Body>
    </>
  );

  useEffect(() => {
    fetchLegalDocument(type)
      .then(({data}) => setLegalDocumentText(parse(data, options)))
      .catch(() =>
        setLegalDocumentText(
          <>
            {headline}
            <Body>Fehler beim Laden der Daten!</Body>
          </>
        )
      );
  }, []);

  return <StyledDiv id={'legal-document'}>{legalDocumentText}</StyledDiv>;
};

export default LegalDocument;
